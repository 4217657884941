import React, { useState } from 'react';
import { styled, theme } from '../Theme';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import HeroModule from '../Shared/Hero/HeroModule';
import NewsArchivePageModel from './Models/NewsArchivePageModel.interface';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import ContentArea from '../Shared/ContentArea/ContentArea';
import NewsCard from '../NewsCard/NewsCard';
import LoadMoreContainer from '../Shared/LoadMoreContainer/LoadMoreContainer';
import NewsCardModel from '../NewsCard/Model/NewsCardModel.interface';
import { media } from '@glitz/core';
import { applyEditModeAttr } from '../../Features/Shared/Common/Helpers';
import { StyleOrStyleArray } from '@glitz/type';
import useMedia from '../Shared/Hooks/useMedia';

function NewsArchivePage() {
  const {
    heading,
    introText,
    bodyText,
    contentArea,
    inEditMode,
    teaserHeader,
    teaserImage,
    teaserLinks,
    teaserText,
    blurTeaserText,
    newsCards,
  } = useCurrentPage<NewsArchivePageModel>();

  const isDesktop = useMedia(theme.mediaQuery.mediaMinLarge);

  const {
    translations: {
      'newsPage/news': news,
      'newsPage/newsMobileCounter': newsMobileCounter,
      'searchPage/counter': counter,
      'searchPage/loadMore': loadMore,
    },
  } = useAppSettingsData();

  const [newsCardsToShow, setNewsCardsToShow] = useState(
    newsCards.slice(0, 10)
  );
  const hasTopContentText = !!heading || !!introText || !!bodyText;
  const totalNewsCards = newsCards.length;
  const counterText = !!newsCards.length
    ? counter
        .replace('{0}', newsCardsToShow.length.toString())
        .replace('{1}', newsCards.length.toString())
    : '';
  const loaded = (newsCardsToShow.length / totalNewsCards) * 100;

  const mobileCounterText =
    !isDesktop && !!newsCards.length
      ? newsMobileCounter
          .replace('{0}', newsCardsToShow.length.toString())
          .replace('{1}', newsCards.length.toString()) +
        ' ' +
        news.toLowerCase()
      : '';

  const increaseNumberOfCardsShown = () => {
    setNewsCardsToShow(newsCards.slice(0, newsCardsToShow.length + 10));
  };

  const disableLoadMoreButton = () => {
    if (newsCardsToShow.length >= newsCards.length) {
      return true;
    }
    return false;
  };

  const introTextMargin: StyleOrStyleArray = {
    marginBottom: bodyText ? theme.great : theme.none,
  };

  return (
    <>
      {teaserImage && (
        <HeroModule
          header={teaserHeader}
          summary={teaserText}
          inEditMode={inEditMode}
          image={teaserImage}
          links={teaserLinks}
          blurText={blurTeaserText}
        />
      )}
      {hasTopContentText && (
        <TopContent>
          <Heading {...applyEditModeAttr(inEditMode && 'Heading')}>
            {heading}
          </Heading>
          <Intro
            css={introTextMargin}
            {...applyEditModeAttr(inEditMode && 'IntroText')}
          >
            {introText}
          </Intro>
          <div
            {...applyEditModeAttr(inEditMode && 'BodyText')}
            dangerouslySetInnerHTML={{
              __html: bodyText,
            }}
          />
        </TopContent>
      )}
      <NewsCardsContainerContainer>
        {!!mobileCounterText.length && (
          <MobileCounterWrapper>{mobileCounterText}</MobileCounterWrapper>
        )}
        <NewsCardWrapper>
          {newsCardsToShow.map((card: NewsCardModel, index) => (
            <NewsCard
              key={card.href}
              item={card}
              isLastItem={index === newsCardsToShow.length - 1}
            />
          ))}
        </NewsCardWrapper>
        {/* <LoadMoreContainer
          loaded={loaded}
          counterText={counterText}
          loadMore={loadMore}
          disableButton={disableLoadMoreButton()}
          onClick={() => increaseNumberOfCardsShown()}
        /> */}
        <ContentArea childItems={contentArea} />
      </NewsCardsContainerContainer>
    </>
  );
}

const NewsCardsContainerContainer = styled.div({
  width: '100%',
  maxWidth: theme.contentMaxWidth,
  margin: {
    x: 'auto',
  },
  padding: {
    x: theme.medium,
  },
});

const MobileCounterWrapper = styled.div({
  height: theme.great,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: theme.tau,
  letterSpacing: theme.letterSpacing.normalWide,
  ...media(theme.mediaQuery.mediaMinSmall, {
    fontSize: theme.alpha,
  }),
});

const NewsCardWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.white,
  width: '100%',
  height: '100%',
  maxWidth: theme.contentResultWidth,
  marginBottom: theme.massive,
  marginRight: 'auto',
  marginLeft: 'auto',
  padding: { xy: theme.great },
  ...media(theme.mediaQuery.mediaMinLarge, {
    marginTop: theme.massive,
  }),
});

const TopContent = styled.div({
  marginTop: theme.huge,
  width: '100%',
  maxWidth: theme.contentResultWidth,
  margin: {
    x: 'auto',
  },
  ...media(theme.mediaQuery.mediaMaxLarge, {
    padding: {
      x: theme.medium,
    },
    marginBottom: theme.huge,
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    marginTop: theme.greater,
  }),
});

const Heading = styled.h2({
  maxWidth: theme.maxWidthSmall,
  font: { size: theme.theta, weight: theme.fontWeight.lighter },
  marginBottom: theme.larger,
  ...media(theme.mediaQuery.mediaMinLarge, {
    font: { size: theme.iota },
    marginBottom: theme.great,
  }),
});

const Intro = styled.p({
  font: { size: theme.gamma, weight: theme.fontWeight.lighter },
  lineHeight: theme.lineHeight.normal,
  letterSpacing: theme.letterSpacing.nearMedium,
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: theme.delta,
  }),
});

export default NewsArchivePage;
