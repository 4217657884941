import React from 'react';
import { styled, theme } from '../Theme';
import Divider from '../../Features/Shared/Divider/Divider';
import KexLink from '../Shared/KexLink/KexLink';

import NewsCardModel from '../NewsCard/Model/NewsCardModel.interface';

type PropType = {
  item: NewsCardModel;
  isLastItem?: boolean;
};

function NewsCard({
  item: { header, href, tags, preamble, publishDate },
  isLastItem,
}: PropType) {
  return (
    <div>
      <TopDiv>
        <DateSpan>{publishDate}</DateSpan>
        <TagsWrapper>
          {tags.map((tag, index) => (
            <p key={index}>
              {tag}
              {index !== tags.length - 1 && ','}
              &nbsp;
            </p>
          ))}
        </TagsWrapper>
      </TopDiv>
      <KexLink href={href}>
        <Heading>{header}</Heading>
        <Preamble>
          {preamble.length >= 190
            ? preamble.substring(0, 190).slice(0, -3) + '...'
            : preamble}
        </Preamble>
      </KexLink>
      {!isLastItem && <StyledDivider />}
    </div>
  );
}

const TagsWrapper = styled.div({
  display: 'flex',
});

const TopDiv = styled.div({
  display: 'flex',
  fontSize: theme.beta,
  lineHeight: theme.lineHeight.moreSnug,
  letterSpacing: theme.letterSpacing.wide,
  justifyContent: 'space-between',
  marginBottom: theme.medium,
});

const Heading = styled.h2({
  fontSize: theme.psi,
  fontWeight: theme.fontWeight.lighter,
  letterSpacing: theme.letterSpacing.wider,
  marginBottom: theme.gamma,
});

const DateSpan = styled.p({
  opacity: 0.7,
});

const Preamble = styled.p({
  opacity: 0.9,
  lineHeight: theme.lineHeight.moreRelaxed,
  letterSpacing: theme.letterSpacing.wide,
});

const StyledDivider = styled(Divider, {
  width: '100%',
  margin: { y: theme.gamma },
});

export default NewsCard;
